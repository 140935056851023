import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

const LotteryContext = createContext({
    detailHistoryData: null,
    setDetailHistoryData: () => {}
});

export const useLottery = () => {
  const context = useContext(LotteryContext);
  if (!context) {
    throw new Error("useLottery must be used within an LotteryProvider");
  }
  return context;
};


export const LotteryProvider = ({ children }) => {
  const [detailHistoryData, setDetailHistoryData] = useState(null);

  return (
    <LotteryContext.Provider
      value={{
        detailHistoryData,
        setDetailHistoryData
      }}
    >
      {children}
    </LotteryContext.Provider>
  );
};
