import React, { useState } from 'react'
import CIcon from '@coreui/icons-react'
import {cibStackexchange, cibEthereum, cibJenkins, cilHamburgerMenu} from '@coreui/icons'
import { CFooter, CLink, CCol } from '@coreui/react'
import { Link } from 'react-router-dom'

import Logo from '../assets/logo.png'
import Banner from '../assets/banner-1.png'
import IconLottery from '../assets/icons/lottery.png'
import IconCalculator from '../assets/icons/calculator.png'
import IconInfo from '../assets/icons/info.png'

const Layout = ({children}) => {
    const [tab, setTab] = useState(0);
    const [showNav, setShowNav] = useState(false);

    return (
        <div>
            <nav className='bg-gradient-to-b from-[#C10000] to-[#880000] h-full !z-2'>
                <div className='text-right flex justify-center'>
                    <CCol xs={11} sm={10} md={10} lg={10} xl={8} xxl={8}>
                        <div className="flex text-white justify-between">
                            <div className='d-none d-sm-block py-2'>
                                <Link to="/">
                                    <img src = {Logo} alt = "logo" width={200} height={50}/>
                                </Link>
                            </div>
                            <div className='d-block d-sm-none py-2'>
                                <Link to="/">
                                    <img src = {Logo} alt = "logo" width={130} height={38}/>
                                </Link>
                            </div>
                            <div className='hidden lg:flex gap-3 items-center' style={{"cursor": "pointer", "userSelect": "none"}}>
                                <span className={"px-2 h-full list flex items-center uppercase  "+(tab==0 ? "active-tab" : "")}>
                                    <Link to="/" onClick={() => setTab(0)} className='flex items-center' >
                                        <img src={IconLottery} alt='Icon Lottery' className='w-[33px] h-[33px]' />
                                        <span className='ml-2 font-semibold text-[15px]'>
                                            Xổ số trực tiếp
                                        </span>
                                    </Link>
                                </span>
                                <span className={"px-2 h-full list flex items-center uppercase "+(tab==1 ? "active-tab" : "")}>
                                    <Link to="/history" onClick={() => setTab(1)} className='flex items-center'>
                                        <img src={IconCalculator} alt='Icon Caculator' className='w-[33px] h-[33px]' />
                                        <span className='ml-2 font-semibold text-[15px]'>
                                        Lịch sử KQxs
                                        </span>
                                    </Link>
                                </span>
                                <span className={"px-2 h-full list flex items-center uppercase "+(tab==2 ? "active-tab" : "")}>
                                    <Link to="/about" onClick={() => setTab(2)} className='flex items-center'>
                                        <img src={IconInfo} alt='Icon Info' className='w-[33px] h-[33px]' />
                                        <span className='ml-2 font-semibold text-[15px]'>
                                            Giới thiệu
                                        </span>
                                    </Link>
                                </span>
                            </div>
                            <div className='block lg:hidden pr-5 flex items-center'>
                                <CIcon icon={cilHamburgerMenu} size={'3xl'} className='cursor-pointer' onClick={() => setShowNav(!showNav)}/>
                            </div>
                        </div>
                    </CCol>
                </div>
                {showNav && <div className='lg:hidden text-white h-[100%] bg-[#200000E5] absolute right-0 pt-1 z-1'>
                    <div className='flex items-center transition ease-in-out hover:bg-red-600 p-2 cursor-pointer'>
                        <span className={"px-5 py-1 h-full list flex items-center "}>
                            <Link to="/" onClick={() => { setShowNav(false); setTab(0); }} className='flex items-center' >
                                    <img src={IconLottery} alt='Icon Lottery' className='w-[33px] h-[33px]' />
                                    <span className='ml-2 font-semibold text-[15px]'>
                                        Xổ số trực tiếp
                                    </span>
                                </Link>
                        </span>
                    </div>    
                    <div className='flex items-center transition ease-in-out hover:bg-red-600 p-2 cursor-pointer'>
                        <span className={"px-5 py-1 h-full list flex items-center "}>
                             <Link to="/history" onClick={() => { setShowNav(false); setTab(1)}} className='flex items-center'>
                                    <img src={IconCalculator} alt='Icon Caculator' className='w-[33px] h-[33px]' />
                                    <span className='ml-2 font-semibold text-[15px]'>
                                    Lịch sử KQxs
                                    </span>
                                </Link>
                        </span>
                    </div>
                    <div className='flex items-center transition ease-in-out hover:bg-red-600 p-2 cursor-pointer'>
                        <span className={"px-5 py-1 h-full list flex items-center"}>
                             <Link to="/about" onClick={() =>{ setShowNav(false); setTab(2)}} className='flex items-center'>
                                    <img src={IconInfo} alt='Icon Info' className='w-[33px] h-[33px]' />
                                    <span className='ml-2 font-semibold text-[15px]'>
                                        Giới thiệu
                                    </span>
                                </Link>
                        </span>
                    </div>
                </div>}
            </nav>
            <div>
                <img className='d-none d-sm-block' src = {Banner} alt = "xx"  style={{position: "relative", width: "100%", height: "320px"}}  />
                <img className='d-block d-sm-none' src = {Banner} alt = "xx"  style={{position: "relative", width: "100%", height: "170px"}}  />
                {/* <div style={{zIndex: 1, position:"absolute", top: "400px", width: "100%"}}> */}
                    {children}
                {/* </div> */}
            </div>
            <CFooter className='!bg-[#333] d-none d-sm-block '>
                <div className='!text-[#fff] m-auto uppercase'>
                    <span>
                        Copyright
                    </span>
                    <span>&copy;  © Số Đỏ Casino</span>
                </div>
            </CFooter>
        </div>
    )
}

export default Layout