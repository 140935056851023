import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './scss/style.scss'

import Home from './pages/home';
import History from './pages/history';
import Layout from './components/Layout';
import HistoryDetail from './pages/historydetail';
import { LotteryProvider } from './contexts/LotteryContext';
import About from './pages/about';

const App = () => {
    return(
        <BrowserRouter>
            <Layout>
                <LotteryProvider>
                    <Routes>
                        <Route exact path="/" element={<Home />}></Route>
                        <Route exact path="/history" element={<History />}></Route>
                        <Route exact path="/history/detail" element={<HistoryDetail />}></Route>
                        <Route exact path="/about" element={<About />}></Route>
                    </Routes>
                </LotteryProvider>
            </Layout>
        </BrowserRouter>
    )
}

export default App;
