import React, { useEffect, useState } from 'react'
import {
    CCard,
    CCardBody,
    CCardFooter,
    CCardHeader,
    CCol,
    CRow,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow,
    CFormCheck
} from '@coreui/react'
import Axios from 'axios'

import TimerBanner from '../assets/timer-banner.png'


const xx = { 
    "turnNum": "06/10/2023-0088",
    "openTime": "2023-10-06 08:50:00",
    "serverTime": "2023-10-06 08:48:59",
    "name": "SODO XSMB 75giây",
    "code": "vnmbmg",
    "navCate": "mb",
    "issueList": [
        {
            "turnNum": "06/10/2023-0087",
            "openNum": "5,8,7,8,2", 
            "openTime": "2023-10-06 08:48:45", 
            "detail": 
            "[\"58782\",\"65392\",\"87276,19132\",\"25218,92224,83428,77454,38767,76008\",\"5412,3757,4622,2504\",\"5132,2968,1667,9678,2761,4093\",\"902,563,416\",\"80,53,12,83\"]",
            "status": 2,
            "replayUrl": null,
            "n11": null
        },
        {
            "turnNum": "06/10/2023-0086",
            "openNum": "4,6,2,0,1",
            "openTime": "2023-10-06 08:47:30",
            "detail": "[\"46201\",\"17298\",\"57561,55479\",\"41869,86728,59826,36474,12857,79941\",\"7340,3408,8217,7033\",\"0167,1946,8531,2146,3655,2258\",\"060,139,892\",\"60,77,74,25\"]",
            "status": 2,
            "replayUrl": null,
            "n11": null 
        }, 
        {
            "turnNum": "06/10/2023-0085",
            "openNum": "4,4,1,9,1",
            "openTime": "2023-10-06 08:46:15",
            "detail": "[\"44191\",\"54237\",\"08205,42476\",\"13385,83670,59747,53058,98127,87753\",\"0383,1619,9740,8184\",\"5132,2745,0016,0356,5759,0859\",\"738,532,657\",\"55,92,38,40\"]",
            "status": 2,
            "replayUrl": null,
            "n11": null 
        },
        {
            "turnNum": "06/10/2023-0084",
            "openNum": "3,0,1,9,8",
            "openTime": "2023-10-06 08:45:00",
            "detail": "[\"30198\",\"61377\",\"54116,71700\",\"19550,86486,73344,24339,14826,66229\",\"3282,5988,1828,2530\",\"6797,7927,7976,2305,1164,9698\",\"713,297,922\",\"76,30,13,85\"]",
            "status": 2,
            "replayUrl": null,
            "n11": null 
        },
        {
            "turnNum": "06/10/2023-0083",
            "openNum": "0,7,2,2,8",
            "openTime": "2023-10-06 08:43:45",
            "detail": "[\"07228\",\"11996\",\"47931,35671\",\"49640,20577,20853,12622,51167,21281\",\"5363,0152,2882,9983\",\"2204,3216,4953,9183,1053,4682\",\"090,640,213\",\"03,64,79,82\"]",
            "status": 2,
            "replayUrl": null,
            "n11": null 
        }
    ] 
}


const Lottery = () => {

    const url = "https://www.sodo56.com/api/front/platform/lottery/history/list/5/vnmbmg";

    const [issueList, setIssueList] = useState([]);
    const [openTime, setOpenTime] = useState('')
    const [serverTime, setServerTime] = useState('');
    const [name, setName] = useState('');
    const [navCate, setNavCate] = useState('');

    const [previousContent, setPreviousContent] = useState('');
    const [initialTimeInSeconds, setInitialTimeInSeconds] = useState(3);
    const [timeRemaining, setTimeRemaining] = useState(initialTimeInSeconds);
    
    const formatTime = (seconds) => {
        const min = Math.floor(seconds / 60);
        const sec = seconds % 60;
        return `00:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
    };
    
    const handleTimeOutAction = () => {
        Axios.get(url).then(
            (res) => {
                let {t} = res.data;
                console.log("data:", res.data);
                console.log("t:", t);
                console.log("previousContent:", previousContent);
                setPreviousContent(t);
                if (t != previousContent) setInitialTimeInSeconds(3*60);
                setIssueList(t.issueList);
                setOpenTime(t.openTime);
                setServerTime(t.serverTime);
                setName(t.name);
                setNavCate(t.navCate);
                setIssueList(t.issueList);

            }
        );
    };
    
    useEffect(() => {
        if (timeRemaining > 0) {
        const timerId = setTimeout(() => {
            setTimeRemaining(prevTime => prevTime - 1);
        }, 1000);
    
        // Cleanup
        return () => clearTimeout(timerId);
        } else {
        // Trigger action and reset timer
        handleTimeOutAction();
        setTimeRemaining(initialTimeInSeconds);
        }
    }, [timeRemaining]);

    useEffect(() => {
        Axios.get(url).then(
            (res) => {
                let {t} = res.data;
                console.log("data:", res.data);
                setIssueList(t.issueList);
                setOpenTime(t.openTime);
                setServerTime(t.serverTime);
                setName(t.name);
                setNavCate(t.navCate);
                setIssueList(t.issueList)
            }
        );
    }, [])

    return (
        <CRow >
            <CCol xs={12} >
                <CRow className='bg-white justify-center'>
                    <CCol xs={8} sm={10} md={8} lg={6} xl={6} xxl={4}>
                        <CCard className='text-center p-2 !border-none mt-[-40px]' style={{background: 'transparent', backgroundImage: `url(${TimerBanner})`, backgroundSize: '100% 100%'}}>
                            <div className='sm:my-2'>
                                <span className='text-white font-semibold text-[10px] sm:text-base uppercase'>Đếm ngược đến thời gian mở giải thưởng</span>
                            </div>
                            <div className='text-black'>
                                {formatTime(timeRemaining).split('').map(char => {
                                    if (char === ':')
                                        return <span className='text-white text-[20px] sm:text-[40px] font-medium'>{char}</span>
                                    else 
                                        return <span className='w-[28px] py-1 sm:w-[57px] inline-block bg-white text-[20px] sm:text-[40px] font-medium mx-1 rounded'>{char}</span>
                                })}
                            </div>
                            <div className='w-full'>
                                <span className='text-white text-[8px] sm:text-base'>Giờ</span>
                                <span className='text-white text-[8px] sm:text-base mx-[60px] sm:mx-[110px]'>Phút</span>
                                <span className='text-white text-[8px] sm:text-base'>Giây</span>
                            </div>
                        </CCard>
                    </CCol>

                </CRow>
                <div className='pt-3'>
                    {
                        issueList.map((issue, index ) => <LotteryCard key = {index} issue = {issue} index = {index}/>)  
                    }
                </div>
            </CCol>
        </CRow>        
    )
}

const LotteryCard = ({issue, index}) => {
    const url = "https://www.sodo56.com/api/front/platform/lottery/history/list/5/vnmbmg";

    const [turnNum, setTurnNum] = useState('');
    const [detail, setDetail] = useState([])
    const [presentList, setPresentList] = useState([]);
    const [heads, setHeads] = useState([]);
    const [tails, setTails] = useState([]);
    const [selectedValue, setSelectedValue] = useState("5");

    const clickCheckRadio = (value) => {
        console.log(value)
        setSelectedValue(value);
    };
        
    useEffect(() => {
        setDetail(JSON.parse(issue.detail));
        setTurnNum(issue.turnNum);
    }, [])

    useEffect(() => {
        let presentList = detail.map(item => {
            let subItems = item.split(',').map(subItem => [subItem.slice(-Number(selectedValue))]);
            // Flatten the array if there's only one sub-item
            return subItems.length === 1 ? subItems[0] : subItems;
        });
        setPresentList(presentList);
        
        // decide Heads
        let result = Array.from({length: 10}, () => []); // Initialize empty lists for each leading digit.

        let presentList2 = detail.map(item => {
            let subItems = item.split(',').map(subItem => [subItem.slice(-2)]);
            // Flatten the array if there's only one sub-item
            return subItems.length === 1 ? subItems[0] : subItems;
        });
        presentList2.flat().forEach(sublist => {
            let num = Array.isArray(sublist) ? sublist[0] : sublist;
            let firstDigit = num[0]; // Get the first character of the string.
            result[parseInt(firstDigit)].push(num.substr(-1)); // Extract the last character and push to the appropriate sublist.
        });
        
        let heads = result.map(sublist => sublist.join(','));
        setHeads(heads);

        //decide Tails
        let tails = Array(10).fill().map(() => new Set());  // Create an array of 10 Sets

        presentList2.flat(2).forEach(num => {
            const endDigit = parseInt(num.slice(-1));  // Get the last character
            const preEndDigit = num.slice(-2, -1);  // Get the second-to-last character
            tails[endDigit].add(preEndDigit);
        });

        let formattedTails = tails.map(set => Array.from(set).join(','));
        setTails(formattedTails);
       

    }, [detail, selectedValue]);

    return (
        <CRow style = {{backgroundColor: "white"}}>
            <CCard className="mb-4 !border-none">
                <CCol xs={12}>
                    <CRow>
                        <CCol xs = {0} sm= {0} md = {2}>
                        </CCol>
                        <CCol xs={12} sm = {12} md = {8}>
                            <CCard className="mb-4 !border-none">
                                <CCardHeader className='p-0'>
                                    <CTable small bordered striped>
                                        <CTableHead>
                                            <CTableRow style={{ display: "none" }}>
                                                <CTableHeaderCell scope="col">#</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Class</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                                <CTableHeaderCell scope="col">Heading</CTableHeaderCell>
                                            </CTableRow>
                                        </CTableHead>
                                        <CTableBody className='font-bold text-center ' >
                                            <CTableRow>
                                                <CTableHeaderCell className='uppercase' scope="row" colSpan={13} style={{color: "white", backgroundColor: "#C30101"}}>Xổ số Miền Bắc thứ 2 {turnNum}</CTableHeaderCell>
                                            </CTableRow>
                                            {presentList.map((present, index) => {
                                                if (present.length >= 6)
                                                return (
                                                    <>
                                                    <CTableRow key = {index}>
                                                        <CTableHeaderCell scope="row" rowSpan={2} className='items-center align-middle'>{index === 0 ? "DB": "Giải thưởng" + index}</CTableHeaderCell>
                                                        {
                                                            present.map((item, itemIndex) =>{
                                                                if (itemIndex < 3)
                                                                return <CTableDataCell colSpan={12*2/present.length } key = {itemIndex} >{item}</CTableDataCell>
                                                            }
                                                            )
                                                        }
                                                    </CTableRow>
                                                    <CTableRow>
                                                        {
                                                            present.map((item, itemIndex) =>{
                                                                if (itemIndex >= 3)
                                                                return <CTableDataCell colSpan={12*2/present.length} key = {itemIndex} >{item}</CTableDataCell>
                                                            }
                                                            )
                                                        }
                                                    </CTableRow>
                                                    </>
                                            )
                                                   
                                                else 
                                                    return (<CTableRow key = {index}>
                                                        <CTableHeaderCell scope="row">{index === 0 ? "DB": "Giải thưởng" + index}</CTableHeaderCell>
                                                        {
                                                            present.map((item, itemIndex) =>
                                                                (
                                                                    index === 0 || index === presentList.length - 1 ? 
                                                                        <CTableDataCell colSpan={12/present.length} key = {itemIndex} style = {{color: "red"}}>{item}</CTableDataCell>
                                                                    :
                                                                        <CTableDataCell colSpan={12/present.length} key = {itemIndex} >{item}</CTableDataCell>

                                                                )
                                                            )
                                                        }
                                                        </CTableRow>
                                                    )
                                            })}
                                        
                                        </CTableBody>
                                    </CTable>
                                </CCardHeader>
                                <CCardBody className='bg-transparent'>
                                        <CFormCheck 
                                            inline 
                                            id={"inlineCheckbox1" + index} 
                                            name={"inlineRadioOptions" + index}
                                            label="Tất cả" 
                                            type='radio'
                                            onChange={(e) => clickCheckRadio("5", e)}
                                            checked={selectedValue === "5"}
                                            className='text-[12px] sm:text-[14px]'
                                        />
                                        <CFormCheck 
                                            inline 
                                            id={"inlineCheckbox2"  + index}
                                            name={"inlineRadioOptions" + index}
                                            label="2 số cuố" 
                                            type='radio' 
                                            onChange={(e) => clickCheckRadio("2", e)}
                                            checked={selectedValue === "2"}
                                            className='text-[12px] sm:text-[14px]'
                                        />
                                        <CFormCheck
                                            inline
                                            name={"inlineRadioOptions" + index} 
                                            id={"inlineCheckbox3" + index}
                                            label="3 số cuối"
                                            type='radio'
                                            onChange={() => clickCheckRadio("3", index)}
                                            checked={selectedValue === "3"}
                                            className='text-[12px] sm:text-[14px]'
                                        />
                                </CCardBody>
                                <CCardFooter>
                                    <div style={{display:"flex", marginTop: "50px"}}>
                                        <CTable style={{  textAlign: "center", marginRight: "20px" }} small bordered>
                                            <CTableHead>
                                                <CTableRow>
                                                    <CTableHeaderCell scope="col">Đầu</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Đuôi</CTableHeaderCell>
                                                </CTableRow>
                                            </CTableHead>
                                            <CTableBody style={{ fontWeight: "bold" }}>
                                                {
                                                    heads.map((heading, index) => (
                                                        <CTableRow key = {index}>
                                                            <CTableHeaderCell scope="row">{index}</CTableHeaderCell>
                                                            <CTableDataCell >{heading}</CTableDataCell>
                                                        </CTableRow>
                                                    ))
                                                }
                                            </CTableBody>
                                        </CTable>
                                        <CTable style={{textAlign: "center", marginLeft: "20px"}} small bordered>
                                            <CTableHead>
                                                <CTableRow>
                                                    <CTableHeaderCell scope="col">Đầu</CTableHeaderCell>
                                                    <CTableHeaderCell scope="col">Đuôi</CTableHeaderCell>
                                                </CTableRow>
                                            </CTableHead>
                                            <CTableBody style={{  fontWeight: "bold" }}>
                                                {
                                                    tails.map((tail, index) => (
                                                        <CTableRow key = {index}>
                                                            <CTableHeaderCell scope="row">{tail}</CTableHeaderCell>
                                                            <CTableDataCell >{index}</CTableDataCell>
                                                        </CTableRow>
                                                    ))
                                                }
                                            </CTableBody>
                                        </CTable>

                                    </div>
                                </CCardFooter>
                            </CCard>
                        </CCol>
                        <CCol xs = {0} sm = {0} md = {2}>
                        </CCol>
                    </CRow>
                </CCol>
                
            </CCard>
        </CRow>
    )
}

export default Lottery;