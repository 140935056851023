import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import { CCard, CCardBody } from '@coreui/react';
import { Link } from 'react-router-dom';
import { useLottery } from '../contexts/LotteryContext';

const History = () => {
    const { setDetailHistoryData } = useLottery();
    const [activeTab, setActiveTab] = useState(1);
    const [data, setData] = useState(null);
    const [nDraws, setNDraws] = useState(10);

    const handleOnDetail = (item) => {
        // console.log(item);
        // return;
        setDetailHistoryData(item);
    }

    const fetchData = useCallback(async () => {
        try {
          const response = await fetch(`https://www.sodo56.com/api/front/platform/lottery/history/list/${nDraws}/vnmiba3m`);
          const result = await response.json();
          setData(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }, [nDraws]);

    useEffect(()=>{
        // Initial data fetch
        fetchData();

        // Set up a timer to fetch data every 3 minutes (180000 milliseconds)
        const timer = setInterval(fetchData, 18000);

        // Clean up the interval when the component unmounts
        return () => {
            clearInterval(timer);
        };
    }, [nDraws]);

    useEffect(() => {
        switch(activeTab) {
            case 1:
                setNDraws(10);
                break;
            case 2:
                setNDraws(30);
                break;
            case 3:
                setNDraws(50);
                break;
            case 4:
                setNDraws(100);
                break;
            default:
                setNDraws(10);
        }
    }, [activeTab])

    return(
        <div className='bg-[#e1e1e1]'>
            <div className='hidden sm:block bg-white lg:px-[12.5%]'>
                <ul className="num-draws nav nav-tabs text-lg font-bold text-[#666]">
                    <li className={`nav-item ${activeTab === 1 ? 'active-tab' : ''}`}>
                    <a className="nav-link" onClick={() => setActiveTab(1)}>
                        10Lượt Xổ
                    </a>
                    </li>
                    <li className={`nav-item ${activeTab === 2 ? 'active-tab' : ''}`}>
                    <a className="nav-link" onClick={() => setActiveTab(2)}>
                        30Lượt Xổ
                    </a>
                    </li>
                    <li className={`nav-item ${activeTab === 3 ? 'active-tab' : ''}`}>
                    <a className="nav-link" onClick={() => setActiveTab(3)}>
                        50Lượt Xổ
                    </a>
                    </li>
                    <li className={`nav-item ${activeTab === 4 ? 'active-tab' : ''}`}>
                    <a className="nav-link" onClick={() => setActiveTab(4)}>
                        100Lượt Xổ
                    </a>
                    </li>
                </ul>
            </div>
            <div className='sm:hidden bg-white lg:px-[12.5%]'>
                <ul className="num-draws nav nav-tabs text-lg font-bold text-[#666] justify-around">
                    <li className={`nav-item ${activeTab === 1 ? 'active-tab' : ''}`}>
                    <a className="nav-link" onClick={() => setActiveTab(1)}>
                        <span className='text-lg'>10 </span>
                        <span> Lượt Xổ</span>
                    </a>
                    </li>
                    <li className={`nav-item ${activeTab === 2 ? 'active-tab' : ''}`}>
                    <a className="nav-link" onClick={() => setActiveTab(2)}>
                        <span className='text-lg'>30</span>
                        <span> Lượt Xổ</span>
                    </a>
                    </li>
                    <li className={`nav-item ${activeTab === 3 ? 'active-tab' : ''}`}>
                    <a className="nav-link" onClick={() => setActiveTab(3)}>
                        <span className='text-lg'>50</span>
                        <span> Lượt Xổ</span>
                    </a>
                    </li>
                    <li className={`nav-item ${activeTab === 4 ? 'active-tab' : ''}`}>
                    <a className="nav-link" onClick={() => setActiveTab(4)}>
                        <span className='text-lg'>100</span>
                        <span> Lượt Xổ</span>
                    </a>
                    </li>
                </ul>
            </div>
            <div className='lg:w-[75%] px-2 mx-auto'>
                <CCard className="w-full overflow-hidden mt-1">
                    <CCardBody className='p-0 overflow-x-scroll'>
                        <table className="table main">
                            <thead className='border-b-4 border-solid border-[red]'>
                                <tr className='py-2'>
                                    <th scope="col" className='text-center'>No.</th>
                                    <th scope="col" className='hidden'>Ngày xổ</th>
                                    <th scope="col" className='hidden'>Lượt xổ</th>
                                    <th scope="col" className='hidden'>Kết quả</th>
                                    <th scope="col" className='hidden invisible'>hoạt động</th>
                                    <th scope='col' className='sm:!hidden w-full'>Ngày xổ Lượt xổ & Kết quả</th>
                                </tr>
                            </thead>
                            <tbody className='px-3 text-xl text-[#333]'>
                                {data?.t?.issueList.map((item, index) => {
                                    const numbersArray = item?.openNum.split(',').map(Number);
                                    return(
                                        <tr key={"issueListItem " + index}>
                                            <td className='text-center lg:text-[18px] text-[14px]'>
                                                <span className='bg-gray-200 px-2 py-1 rounded'>{index+1}</span>
                                            </td>
                                            <td className='hidden sm:block text-center lg:text-[18px] text-[14px]'>
                                                <div className='date relative d-flex items-center'>
                                                    {moment(item.openTime, "YYYY-MM-DD HH:mm:ss").format("DD/MM")}
                                                </div>
                                            </td>
                                            <td className='hidden sm:block lg:w-[200px] lg:text-[18px] text-[14px]'>
                                                {item.turnNum}
                                            </td>
                                            <td className='hidden sm:block '>
                                                <div className="ball flex items-center h-[72px]">
                                                    {numbersArray.map((ballItem, ind) => <span className='block' key={index+"ballItem"+ballItem+" "+ind}>{ballItem}</span>)}
                                                </div>
                                            </td>
                                            <td className='hidden sm:block '>
                                                <Link to={{pathname: '/history/detail', search: '?turnNum='+item.turnNum}} onClick={() => handleOnDetail(item)}>
                                                    <div className='btn !bg-[#F9A447] text-white'>Chi tiết</div>
                                                </Link>
                                            </td>
                                            <td className='sm:!hidden'>
                                                <div>
                                                    <span className='text-sm text-[red] font-bold ml-[-10px]'>{item.turnNum}</span>
                                                    <span className='relative items-center text-xs'>
                                                        &nbsp;{
                                                            moment(item.openTime, "YYYY-MM-DD HH:mm:ss").locale('vi').format("DD/MM/YYYY (dddd) HH:mm")
                                                        }
                                                    </span>
                                                </div>
                                                <div style={{marginLeft: '-85px'}}>
                                                    <div className="ball flex items-center">
                                                        {numbersArray.map((ballItem, ind) => <span className='block text-base' key={index+"ballItem"+ballItem+" "+ind}>{ballItem}</span>)}
                                                        <Link to={{pathname: '/history/detail', search: '?turnNum='+item.turnNum}} onClick={() => handleOnDetail(item)}>
                                                            <div className='btn !bg-[#F9A447] text-white !text-sm'>Chi tiết</div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </CCardBody>
                </CCard>
            </div>
        </div>
    )
}

export default History;