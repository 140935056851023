import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/vi'; // Import just the Vietnamese locale
import { CCard, CCardHeader, CCardBody, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilAlarm } from '@coreui/icons';

import Icon from '../assets/vnmiba3m.png'
import { Link, useNavigate } from 'react-router-dom';
import { useLottery } from '../contexts/LotteryContext';

const HistoryDetail = () => {
    const { detailHistoryData } = useLottery();
    const navigate = useNavigate();
    const [seconds, setSeconds] = useState(180); // 18000 seconds = 3 minutes
    const [isActive, setIsActive] = useState(true);

    const [data, setData] = useState(null);
    const [result, setResult] = useState([]);
    // Use useParams to access the turnNum parameter
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const turnNum = searchParams.get('turnNum');

    const formatNumber = (number) => {
        return number.toString().padStart(2, '0');
      };
    
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const fetchData = async () => {
        try {
          const response = await fetch(`https://www.sodo56.com/api/front/platform/lottery/recent/list/vnmiba3m`);
          const res = await response.json();
          setData(res);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      useEffect(() => {
        let timer;
    
        if (isActive && seconds > 0) {
          timer = setInterval(() => {
            setSeconds(seconds - 1);
    
            if (seconds === 1) {
              setIsActive(false); // Stop the timer when it reaches 0
              clearInterval(timer);
            }
          }, 1000);
        }
    
        return () => {
          clearInterval(timer);
        };
      }, [seconds, isActive])

    useEffect(()=>{
        console.log('detailHistory:', detailHistoryData);
        if(detailHistoryData==null) {
            navigate("/history");
            return
        }
        let parsedData = JSON.parse(detailHistoryData.detail);
        let res = parsedData.map((item) => {
            return item.split(',').map((subItem) => {
              if (subItem.includes(',')) {
                return subItem.split(',').map(Number);
              } else {
                return subItem.split('').map(Number);
              }
            });
          });
        // Map the parsed data to create an array of numbers
        setResult(res);
        // Initial data fetch
        fetchData();
    }, [turnNum]);

    console.log('rendered.', result, data);

    return(
        <div className='bg-[#e1e1e1]'>
            <div className='bg-white px-[12.5%] py-2 w-full d-flex justify-between'>
                <img className='w-[100px]' src={Icon} />
                <div className='flex items-center'>
                    <Link to="/history">
                        <div className='btn btn-small !bg-[red] text-white'>Lịch sử</div>
                    </Link>
                </div>
            </div>
            <>
                <CCard className="lg:w-[75%] w-[95%] m-auto overflow-hidden mt-1">
                    <CCardHeader className='bg-white !px-[20px] !py-[10px]'>
                        <div className='flex justify-between'>
                            <div className='drawing-date'>
                                <span className='text-sm'>Ngày xổ:</span>
                                <span className='pl-[10px] text-lg'>
                                    {
                                        moment(detailHistoryData?.openTime, "YYYY-MM-DD HH:mm:ss").locale('vi').format("DD/MM/YYYY (dddd) HH:mm")
                                    }
                                </span>
                            </div>
                            <div>
                                <span className='number-dates'>
                                    Lượt xổ: 
                                </span>
                                <CDropdown className='dates-dropdown'>
                                    <CDropdownToggle className='!text-[14px] !bg-[#fffafa] !text-[red] border-0'>{detailHistoryData?.turnNum}</CDropdownToggle>
                                    <CDropdownMenu>
                                        {
                                            data?.rows[0].issueList.map(item => <CDropdownItem className='!text-[14px]' href="#" key={"dropdown"+item}>{item?.turnNum}</CDropdownItem>)
                                        }                                    
                                    </CDropdownMenu>
                                </CDropdown>
                            </div>
                        </div>
                    </CCardHeader>
                    <CCardBody className='!px-[20px] !py-[10px]'>
                        <span className='text-[14px]'>
                            Kết quả:
                        </span>
                        {
                            result.length && <div>
                                <div className='reward-label'>
                                    Giải Đặc Biệt:
                                </div>
                                {
                                    result[0].map((item, ind) => {
                                        return(
                                            <div className='lottery-number flex' key={item+" "+ind}>
                                                {item.map((ballItem, index) => <span className='block' key={ind+"ballItem"+ballItem+" "+index}>{ballItem}</span>)}
                                            </div>
                                        )
                                    })
                                }
                                <div className='reward-label text-[14px]'>
                                    Giải nhất:
                                </div>
                                {
                                    result[1].map((item, ind) => {
                                        return(
                                            <div className='reward-list flex' key={item+" "+ind}>
                                                <div className='reward-info flex'>
                                                    {item.map((ballItem, index) => <span className='block' key={ind+"ballItem"+ballItem+" "+index}>{ballItem}</span>)}
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                <div className='reward-label text-[14px]'>
                                    Giải nhì:
                                </div>
                                {
                                    <div className='flex flex-wrap'>
                                        {
                                            result[2].map((item, ind) => {
                                                return(
                                                    <div className='reward-list flex px-2' key={item+" "+ind}>
                                                        <div className='reward-info small-ball flex'>
                                                            {item.map((ballItem, index) => <span className='block' key={ind+"ballItem"+ballItem+" "+index}>{ballItem}</span>)}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <div className='reward-label text-[14px]'>
                                    Giải ba:
                                </div>
                                {
                                    <div className='flex flex-wrap'>
                                        {
                                            result[3].map((item, ind) => {
                                                return(
                                                    <div className='reward-list flex' key={item+" "+ind}>
                                                        <div className='reward-info small-ball flex'>
                                                            {item.map((ballItem, index) => <span className='block' key={ind+"ballItem"+ballItem+" "+index}>{ballItem}</span>)}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <div className='reward-label text-[14px]'>
                                    Giải tư:
                                </div>
                                {
                                    <div className='flex flex-wrap'>
                                        {
                                            result[4].map((item, ind) => {
                                                return(
                                                    <div className='number-just flex' key={item+" "+ind}>
                                                        <div className='number-ball flex'>
                                                            {item}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <div className='reward-label text-[14px]'>
                                    Giải năm:
                                </div>
                                {
                                    <div className='flex flex-wrap'>
                                        {
                                            result[5].map((item, ind) => {
                                                return(
                                                    <div className='number-just flex' key={item+" "+ind}>
                                                        <div className='number-ball flex'>
                                                            {item}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <div className='reward-label text-[14px]'>
                                    Giải sáu:
                                </div>
                                {
                                    <div className='flex flex-wrap'>
                                        {
                                            result[6].map((item, ind) => {
                                                return(
                                                    <div className='number-just flex' key={item+" "+ind}>
                                                        <div className='number-ball flex'>
                                                            {item}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                                <div className='reward-label text-[14px]'>
                                    Giải bảy:
                                </div>
                                {
                                    <div className='flex flex-wrap'>
                                        {
                                            result[7].map((item, ind) => {
                                                return(
                                                    <div className='number-just flex' key={item+" "+ind}>
                                                        <div className='number-ball flex'>
                                                            {item}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                }
                            </div>
                        }
                        <div className='flex !py-3 items-center'>
                            <div className='text-[14px] text-[#666]'>
                                Kết quả:
                                <span className='ml-[10px]'>{detailHistoryData?.turnNum}</span>
                            </div>
                            <div className='px-2 sm:px-5'>
                                <CIcon icon={cilAlarm}  style={{'--ci-primary-color': 'red'}} />
                                {isActive ? <span className='px-2'>{formatNumber(minutes)} : {formatNumber(remainingSeconds)}</span> : <span className='px-2 text-[18px] text-[#333]'>Đang mở giải</span>}
                                
                            </div>
                        </div>
                    </CCardBody>
                </CCard>
            </>
        </div>
    )
}

export default HistoryDetail;