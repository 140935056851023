import React from "react";
import { CRow, CCol } from "@coreui/react";

import AboutImg from '../assets/about.png';
import LogoImg from '../assets/logo.png';

const About = () => {
    return (
        <CRow className="justify-center !mx-0 !px-0">
            <CCol className="!mx-0 !px-0" xs={12} sm={10} md={10} lg={10} xl={8} xxl={8}>
                <div className="m-auto md:flex gap-3 py-5 items-center">
                    <div className="!w-full md:w-[65%]">
                        <img src={AboutImg} width={720} alt="about" />
                    </div>
                    <div className="md:w-[35%]">
                        <div className="bg-[#C20101] flex justify-center">
                            <img className="d-none d-sm-block py-2" src={LogoImg} width={256} alt="about" />
                            <img className="d-block d-sm-none py-2" src={LogoImg} width={240} alt="about" />
                        </div>
                        <div className="text-center">
                            <span className="text-[16px] md:text-[18px] text-black px-2">
                                Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui.
                            </span>
                        </div>
                    </div>

                </div>
            </CCol>
        </CRow>
    )
}

export default About;